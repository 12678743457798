@import "/src/styles/all-imports";
.banner-inner {
  width: 100%;
  float: left;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: $brand-pink;
  text-align: center;
  @include resp-tab {
    text-align: left;
  }
//   &::before {
//     content: '';
//     background-image: url('../../images/pattern1.svg');
//     width: 104px;
//     height: 104px;
//     position: absolute;
//     top: 75px;
//     right: 10%;
//     background-size: 100% auto;
//     display: block;
//     opacity: 0.5;
//     @include resp-tab {
//       width: 204px;
//       height: 204px;
//     }
//   }
//   &::after {
//     content: '';
//     background-image: url('../../images/pattern1.svg');
//     width: 204px;
//     height: 204px;
//     position: absolute;
//     bottom: 50px;
//     left: 10%;
//     background-size: 100% auto;
//     display: block;
//     opacity: 0.5;
//     @include resp-tab {
//       width: 404px;
//       height: 404px;
//     }
//   }
  h1 {
    margin-bottom: 0;
  }
  p {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 0;
    @include resp-tab {
      font-size: 17px;
    }
    @include resp-desk {
      font-size: 19px;
    }
  }
  .btn {
    margin-top: 20px;
  }
  .profile-img {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 40px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    // background-image: url('../../images/profile-image.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $white;
    border: solid 3px $white;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    overflow: hidden;
    @include resp-tab {
      width: 80px;
      height: 80px;
      margin-left: 0;
      margin-right: 12px;
    }
    .edit-profile-pic {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 5px 0;
      text-align: center;
      display: none;
      background-color: rgba(0,0,0,0.4);
      color: #fff;
    }
    &:hover {
      .edit-profile-pic {
        display: block;
      }
    }
  }
  .dash-top-wrap {
    display: block;
    margin-top: 10px;
    @include resp-tab {
      margin-top: 0;
      display: inline-block;
      vertical-align: middle;
    }
  }
  // &.add-listing-banner {
	//   margin-top: 109px;
  // }
  &.attraction {
	  background: #008795;
  }
}
