@import "/src/styles/all-imports";
/* About -------------------------------------------------------------------- */
.about-block {
  padding-bottom: 230px;
  @include resp-tab {
    padding-bottom: 40px;
  }
  @include resp-desk {
    padding-bottom: 80px;
  }
  h2 {
    color: $brand-pink;
  }
  p {
    color: $brand-purple;
  }
  .about-img1, .about-img2, .pattern1 {
    width: 120px;
    height: 120px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    @include resp-tab {
      width: 170px;
      height: 170px;
    }
    @include resp-desk {
      width: 200px;
      height: 200px;
    }
  }
  .pattern1 {
    width: 80px;
    height: 80px;
    right: 255px;
    top: 30px;
    background-image: url('../../images/pattern1-purple.svg');
    background-size: cover;
    background-position: left top;
    @include resp-tab {
      width: 120px;
      height: 120px;
      right: 88px;
      top: 240px;
    }
    @include resp-desk {
      width: 120px;
      height: 120px;
      right: 348px;
      top: 90px;
    }
  }
  .about-img1 {
    right: 45px;
    top: 0;
    z-index: 3;
    background-image: url('../../images/about1.jpg');
    border-radius: 10px;
    display: none;
    @include resp-tab {
      border-radius: 22px;
      right: 15px;
      top: 15px;
      display: block;
    }
    @include resp-desk {
      top: 0;
    }
  }
  .about-img2 {
    right: 65px;
    top: 52px;
    z-index: 2;
    background-image: url('../../images/about2.jpg');
    border-radius: 10px;
    width: 70px;
    height: 70px;
    @include resp-tab {
      right: 162px;
      top: 150px;
      width: 120px;
      height: 120px;
      border-radius: 22px;
    }
  }
}
