@import '../../styles/all-imports';

#cara-listing,
#reactCarousel {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background: none;
  height: 206px;
  @include rounded-corners;
  overflow: hidden;
  @include resp-tab {
    border-radius: 0;
    height: 338px;
  }
  @include resp-desk {
    height: 540px;
  }
  .carousel-inner {
    @include rounded-corners;
    overflow: hidden;
    @include resp-tab {
      border-radius: 15px 0 0 15px;
    }
  }
  .carousel-item {
    background-size: cover;
    height: 206px;
    @include resp-tab {
      height: 334px;
    }
    @include resp-desk {
      height: 540px;
    }
  }
}
.blur-image {
  background-size: cover;
}
.blur-div-behind {
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  padding-top: 15px;
  padding-bottom: 15px;
  @include resp-tab {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @include resp-desk {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.cara-sidebar-top {
  padding: 20px;
  @include resp-tab {
    padding: 20px;
    height: 200px;
  }
  @include resp-desk {
    padding: 0 40px;
    height: 215px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  @include resp-desk {
    padding: 0 30px;
  }
  h2 {
    margin-bottom: 10px;
    font-size: 20px;
    @include resp-desk {
      font-size: 28px;
    }
    &::first-letter {
      text-transform: capitalize;
    }
  }
  h6 {
    color: $brand-pink;
    margin-bottom: 0;
  }
  .date {
    margin-top: 15px;
    color: $white;
    display: block;
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      display: inline-block;
      background-image: url('../../images/calendar-icon.svg');
      background-repeat: no-repeat;
      vertical-align: middle;
      background-size: 14px 14px;
      margin: -1px 4px 0 0;
      @include resp-tab {
        background-size: 14px 16px;
        margin: -5px 4px 0 0;
      }
      @include resp-xl {
        background-size: 16px 16px;
        margin: -5px 8px 0 0;
      }
    }
  }
  .time {
    color: $white;
    margin-top: 5px;
    display: block;
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      display: inline-block;
      background-image: url('../../images/time-icon.svg');
      background-repeat: no-repeat;
      vertical-align: middle;
      background-size: 14px 14px;
      margin: -1px 4px 0 0;
      @include resp-tab {
        margin: -3px 4px 0 0;
      }
      @include resp-xl {
        margin: -3px 8px 0 0;
      }
    }
  }
}
.cara-sidebar {
  @include rounded-corners;
  overflow: hidden;
  padding-bottom: 20px;
  margin-top: 15px;
  @include resp-tab {
    margin-top: 0;
    padding-bottom: 0;
    border-radius: 0 15px 15px 0;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    border-top: solid 1px rgba(255,255,255,0.4);
    border-bottom: solid 1px rgba(255,255,255,0.4);
    margin-bottom: 20px;
    overflow: hidden;
    @include resp-desk {
      border-bottom: none;
      margin-bottom: 0px;
    }
    li {
      width: 50%;
      height: 54px;
      line-height: 54px;
      display: block;
      float: left;
      text-align: center;
      display: block;
      color: $white;
      margin: 0;
      @include resp-tab {
        height: 48px;
        line-height: 48px;
      }
      @include resp-desk {
        height: 54px;
        line-height: 54px;
      }
    }
    li:first-of-type {
      border-right: solid 1px rgba(255,255,255,0.4);
    }
    li.distance::before {
      content: '';
      width: 33px;
      height: 50px;
      background-image: url('../../images/car-icon.svg');
      background-repeat: no-repeat;
      background-position: center 16px;
      background-size: 33px 16px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    li.likes::before {
      content: '';
      width: 25px;
      height: 24px;
      background-image: url('../../images/heart-icon.svg');
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 25px 22px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    // li.interested::before {
    //   content: '';
    //   width: 25px;
    //   height: 24px;
    //   background-image: url('../../images/interested-icon.svg');
    //   background-repeat: no-repeat;
    //   background-position: center top;
    //   background-size: 25px 22px;
    //   display: inline-block;
    //   vertical-align: middle;
    //   margin-right: 10px;
    // }
    li.interested .favbtn {
      display: inline-block;
    }
  }
  .listing-map {
    background-color: $white;
    height: 270px;
    display: none;
    @include resp-desk {
      display: block;
    }
  }
  .get-directions {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    @include resp-tab {
      width: calc(100% - 30px);
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 20px;
    }
    @include resp-desk {
      display: none;
    }
  }
}
.carousel-control-prev {
  width: 25px;
  height: 50px;
  position: absolute;
  top: 110px;
  margin-top: -25px;
  left: 15px;
  background-repeat: no-repeat;
  background-image: url('../../images/chev-left-white.svg');
  background-size: 12px auto;
  background-position: 6px center;
  background-color: rgba(255,255,255,0.4);
  @include resp-tab {
    top: 167px;
    left: 0;
  }
  @include resp-desk {
    background-color: transparent;
    left: -80px;
    top: 270px;
    width: 60px;
    height: 60px;
    margin-top: -30px;
    background-image: url('../../images/cara-btn-left.svg');
    background-size: 60px 60px;
    background-position: center center;
  }
  @include resp-xl {
    left: -100px;
  }
}
.carousel-control-next {
  width: 25px;
  height: 50px;
  position: absolute;
  top: 110px;
  margin-top: -25px;
  right: 15px;
  background-repeat: no-repeat;
  background-image: url('../../images/chev-right-white.svg');
  background-size: 12px auto;
  background-position: 7px center;
  background-color: rgba(255,255,255,0.4);
  @include resp-tab {
    top: 167px;
    right: 240px;
  }
  @include resp-desk {
    background-color: transparent;
    right: -80px;
    top: 270px;
    width: 60px;
    height: 60px;
    margin-top: -30px;
    background-image: url('../../images/cara-btn-right.svg');
    background-size: 60px 60px;
    background-position: center center;
  }
  @include resp-xl {
    right: -100px;
  }
}
.carousel-indicators li {
  margin-top: 0;
  margin-bottom: 0;
  @include resp-tab {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  @include resp-desk {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
/* Attraction listings color overrides ======================================= */
.attraction {
  .cara-sidebar {
    .cara-sidebar-top h6 {
      color: $brand-blue;
    }
    ul {
      li.distance::before {
        background-image: url('../../images/car-icon-blue.svg');
      }
    }
  }
  .listing-content {
    h2 {
      color: $brand-blue;
    }
  }
  .listing-contact {
    background-color: $brand-blue;
  }
  .social-links li a {
    background-color: $brand-blue;
    &:hover {
      background: transparent;
    }
    &.fb-link {
      &:hover {
        &::before {
          background-image: url('../../images/facebook-icon-blue.svg');
        }
      }
    }
    &.tw-link {
      &:hover {
        &::before {
          background-image: url('../../images/twitter-icon-blue.svg');
        }
      }
    }
    &.in-link {
      &:hover {
        &::before {
          background-image: url('../../images/instagram-icon-blue.svg');
        }
      }
    }
  }
  .listing-contact .website-icon {
    color: $brand-blue;
    background-image: url('../../images/website-icon-blue.svg');
    border: none;
    &:hover {
      background-image: url('../../images/website-icon.svg');
      background-color: $brand-blue;
      color: $white;
    }
  }
  .listing-contact .tel-icon {
    color: $brand-blue;
    background-image: url('../../images/tel-icon-blue.svg');
    border: none;
    &:hover {
      background-image: url('../../images/tel-icon.svg');
      background-color: $brand-blue;
      color: $white;
    }
  }
  .listing-contact .ticket-icon {
    color: $brand-blue;
    background-image: url('../../images/ticket-icon-blue.svg');
    border: none;
    &:hover {
      background-image: url('../../images/ticket-icon.svg');
      background-color: $brand-blue;
      color: $white;
    }
  }
  .social-share a.twitter {
    background-color: $brand-blue;
  }
  .route-block {
    .pink {
      color: $brand-blue;
    }
    .btn {
      @include btn-blue;
    }
  }
  .sign-up-icon::after {
    background-image: url('../../images/signup-icon-blue.svg');
  }
  .route-block.rb-post {
    background-color: $brand-blue;
  }
  .btn-white {
    color: $brand-blue;
    &:hover {
      color: $brand-blue;
    }
  }
  .sitemap, .footer2 {
    a:hover {
      color: $brand-blue;
    }
  }
  .listing-cats ul .cat-tick {
    background-image: url('../../images/checkbox-icon-blue.svg');
  }
}
