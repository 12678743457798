@import '../../styles/all-imports';
.back-to-res h6 a {
  color: $brand-purple;
  text-decoration: none;
  &::before {
    content: '';
    width: 22px;
    height: 22px;
    background-image: url('../../images/chev-left.svg');
    background-repeat: no-repeat;
    background-size: auto 17px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 3px;
    @include resp-tab {
      background-size: auto 22px;
      margin-top: -2px;
    }
  }
  span {
    display: none;
    @include resp-tab {
      display: inline-block;
    }
  }
}
.back-sharing, .back-sharing-mob {
  padding-bottom: 20px;
  display: none;
  @include resp-tab {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    border: none;
  }
  hr {
    border-top-color: $white;
    margin-bottom: 30px;
    @include resp-tab {
      display: none;
    }
  }
  h6 {
    display: inline-block;
    vertical-align: middle;
    height: 40px;
    line-height: 40px;
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 14px;
    color: $brand-purple;
    @include resp-tab {
      font-size: 15px;
      margin-right: 20px;
    }
  }
  .social-share a {
    display: inline-block;
    vertical-align: middle;
    border-radius: 10px;
    overflow: hidden;
    text-indent: 100px;
    width: 48px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: auto 20px;
    background-position: center center;
    @include resp-tab {
      width: 60px;
    }
  }
}
.back-sharing-mob {
  display: block;
  padding-top: 30px;
  padding-bottom: 0px;
  @include resp-tab {
    display: none;
  }
}
.white-bg .social-share h6 {
  color: $brand-purple;
}
.social-share a.facebook {
  background-color: $brand-purple;
  background-image: url('../../images/facebook-icon.svg');
  background-size: auto 24px;
}
.social-share a.twitter {
  background-color: $brand-pink;
  background-image: url('../../images/twitter-icon.svg');
  margin-left: 5px;
  @include resp-tab {
    margin-left: 10px;
  }
}
.attraction {
  .back-to-res h6 a::before {
    background-image: url('../../images/chev-left-blue.svg');
  }
}
