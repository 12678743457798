@import '../../styles/all-imports';
.first-para p:first-of-type {
  font-size: 15px;
  @include resp-tab {
    font-size: 14px;
  }
  @include resp-desk {
    font-size: 17px;
    @include font-bd;
  }
}
.listing-content {
  padding-top: 25px;
  width: 100%;
  display: block;
  @include resp-tab {
    padding-top: 0;
    padding-right: 20px;
  }
  h2 {
    color: $brand-pink;
  }
  p {
    color: $brand-purple;
  }
  figure {
    margin: 0;
    img {
      width: 100%;
      height: auto;
      @include rounded-corners;
      margin-bottom: 20px;
    }
  }
  ul {
    margin-left: 16px;
    margin-bottom: 15px;
    list-style: disc;
    li {
      color: $brand-purple;
      margin-bottom: 10px;
    }
  }
  ol {
    margin-left: 16px;
    margin-bottom: 15px;
    li {
      color: $brand-purple;
      margin-bottom: 10px;
    }
  }
  a:link {
    color: $brand-pink;
  }
  blockquote {
    padding: 25px;
    border-left: solid 3px $brand-pink;
    margin: 15px 0 30px 0;
    background-color: #f1f1f1;
    @include rounded-corners;
    p {
      font-size: 25px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
.attraction .listing-content {
  a:link {
    color: $brand-blue;
  }
  blockquote {
    border-left: solid 3px $brand-blue;
  }
}
.related-posts {
  width: 100%;
  float: left;
  background-color: $grey;
  display: none;
  @include resp-tab {
    display: block;
  }
  h2 {
    color: $brand-purple;
  }
  .eblock-wrap {
    margin-bottom: 0;
  }
}
.block-titles {
  margin-bottom: 15px;
  @include resp-tab {
    margin-bottom: 25px;
  }
  h2 {
    margin-bottom: 0;
    @include resp-tab {
      line-height: 48px;
    }
  }
  .btn {
    margin-top: 0px;
    margin-bottom: 10px;
  }
}


.listing-content {
  @include resp-tab {
    min-height: 500px;
  }
}

/* Sticky Sidebar */
.spsideBarWrap {
  display: inline-block;
  overflow: initial;
  overflow: hidden;
  height: 100%;
  @include resp-tab {
    position: sticky;
    top: 100px;
  }
  .stickMe {
    margin-top: 20px;
    height: auto;
    @include resp-tab {
      position: sticky;
    }
    &.attraction {
      h3 {
        color: $brand-blue;
      }
    }
  }
}

.sticky-draft {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(28,9,49,0.9);
  padding: 25px 15px;
  z-index: 999;
  display: flex;
  align-items: center;
  h3 {
    color: $white;
    display: inline-block;
    margin: 0;
    vertical-align: middle;
  }
}
