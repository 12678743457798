@import "/src/styles/all-imports";
// eblocks
.eblock-wrap {
  margin-bottom: 20px;
  @include resp-tab {
    margin-bottom: 30px;
  }
}
.eblock {
  @include box-shadow;
  float: left;
  background-color: $white;
  @include rounded-corners;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  position: relative;
  @include resp-tab {
    height: 100%;
  }
  &:hover {
    transform: scale(1.04);
    @include box-shadow-hover;
  }
  .eblock-img {
    height: auto;
    position: relative;
    background-color: #000;
    float: left;
    width: 130px;
    height: 130px;
    margin-right: 10px;
    border-radius: 10px;
    overflow: hidden;
    img {
      height: 100%;
      width: auto;
      margin-left: -45px;
      @include resp-tab {
        margin-left: 0;
        width: 100%;
        height: auto;
      }
    }
    @include resp-tab {
      width: 100%;
      height: auto;
      margin-right: 0;
      position: relative;
      background-color: #000;
      border-radius: 10px 10px 0 0;
    }
    .distance {
      position: absolute;
      top: 10px;
      left: 0px;
      right: auto;
      padding: 4px 6px 2px 6px;
      background: rgba(255,255,255,0.9);
      border-radius: 0 5px 5px 0;
      font-size: 11px;
      background: $brand-pink;
      color: $white;
      @include font-bd;
      @include resp-tab {
        top: 10px;
        left: auto;
        right: 10px;
        background-size: 26px auto;
        font-size: 12px;
        background-position: center 10px;
        height: 30px;
        line-height: 32px;
        border-radius: 6px;
        width: 54px;
      }
      @include resp-xl {
        background-size: 33px 16px;
        border-radius: 8px;
        font-size: 14px;
        width: 70px;
      }
      /*&:before {
        content: '';
        width: 33px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        background-image: url(../../images/car-icon.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 33px 16px;
        margin-right: 8px;
        margin-top: -1px;
      }*/
    }
  }
  .eblock-dets {
    width: calc(100% - 145px);
    margin-left: 5px;
    padding: 15px 15px 15px 0;
    float: left;
    @include resp-tab {
      width: 100%;
      margin-left: 0;
      padding: 18px 12px 13px 12px;
      float: left;
    }
    @include resp-desk {
      padding: 18px 15px 13px 15px;
    }
    @include resp-xl {
      padding: 23px 20px 18px 20px;
    }
    ul {
      display: block;
      width: 100%;
      float: left;
      margin-bottom: 6px;
      @include resp-tab {
        margin-bottom: 5px;
      }
      @include resp-xl {
        margin-bottom: 10px;
      }
      li {
        display: inline-block;
        width: 50%;
        float: left;
        color: $brand-purple;
        background-repeat: no-repeat;
        background-position: left center;
        vertical-align: middle;
        height: 16px;
        line-height: 16px;
        margin: 0;
        font-size: 10px;
        @include resp-tab {
          font-size: 10px;
        }
        @include resp-desk {
          font-size: 13px;
        }
        @include resp-xl {
          font-size: 14px;
        }
        &:last-child {
          text-align: right;
        }
        &:before {
          content: '';
          width: 16px;
          height: 16px;
          display: inline-block;
          background-image: url('../../images/calendar-icon.svg');
          background-repeat: no-repeat;
          vertical-align: middle;
          background-size: 14px 14px;
          margin: -1px 4px 0 0;
          @include resp-tab {
            background-size: 14px 16px;
            margin: -5px 4px 0 0;
          }
          @include resp-xl {
            background-size: 16px 16px;
            margin: -5px 8px 0 0;
          }
        }
        &.time::before {
          background-image: url('../../images/time-icon.svg');
          margin: -1px 4px 0 0;
          @include resp-tab {
            margin: -3px 4px 0 0;
          }
          @include resp-xl {
            margin: -3px 8px 0 0;
          }
        }
      }
    }
    h3 {
      display: block;
      width: 100%;
      float: left;
      font-size: 16px;
      margin-bottom: 2px;
      color: $brand-pink;
      @include resp-tab {
        display: block;
        width: 100%;
        float: left;
        margin-bottom: 4px;
      }
      @include resp-xl {
        font-size: 20px;
        margin-bottom: 4px;
      }
    }
    p {
      display: block;
      float: left;
      width: 100%;
      margin: 0;
      font-size: 12px;
      color: $brand-purple;
      @include resp-tab {
        font-size: 13px;
      }
      @include resp-xl {
        font-size: 14px;
      }
    }
  }
  &.feat-listing {
    .featured-tag {
      background-color: $brand-pink;
      width: auto;
      position: absolute;
      top: 10px;
      left: 130px;
      color: #fff;
      text-align: left;
      font-size: 9px;
      padding: 4px 6px 2px 6px;
      margin-left: 15px;
      text-transform: uppercase;
      font-weight: 700;
      z-index: 1;
      border-radius: 5px;
      @include resp-tab {
        font-size: 11px;
        width: 170px;
        padding: 9px 10px 7px 25px;
        line-height: 1.4em;
        margin-left: 0;
        transform: rotate(-31deg);
        top: -8px;
        left: -25px;
        border-radius: 0;
      }
    }
    .eblock-dets {
      padding-top: 40px;
      @include resp-tab {
        padding: 18px 12px 13px 12px;
      }
      @include resp-desk {
        padding: 18px 15px 13px 15px;
      }
      @include resp-xl {
        padding: 23px 20px 18px 20px;
      }
    }
  }
}
.eblock.e-attraction {
  .eblock-img {
    .distance {
      background-color: $brand-blue;
      color: $white;
    }
  }
  .eblock-dets {
    h3 {
      color: $brand-blue;
    }
    ul li:before {
      background-image: url("../../images/calendar-icon-blue.svg");
    }
    ul li.time::before {
      background-image: url("../../images/time-icon-blue.svg");
    }
  }
  .featured-tag {
    background-color: $brand-blue;
  }
}


  .eblock .btn {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -70px;
    margin-top: -24px;
  }
  .eblock:hover {
    transform: none;
    @include box-shadow;
    .btn {
      display: inline-block;
    }
  }
  .banner-inner.dashboard {
    .btn {
      margin-top: 10px;
      @include resp-tab {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -24px;
      }
    }
  }
  .status-wrap {
    z-index: 1;
    padding: 0 0 10px 0;
    border-radius: 10px 10px 0 0;
    background: none;
    @include resp-tab {
      padding: 15px;
      position: absolute;
      top: 0;
      left: 15px;
      right: 15px;
      @include grad6;
    }
    .status {
      color: $white;
      height: 24px;
      line-height: 24px;
      padding: 0 10px;
      min-width: 70px;
      text-transform: uppercase;
      font-size: 12px;
      @include font-bd;
      border-radius: 4px;
      display: inline-block;
      text-align: center;
      float: right;
    }
    .status.live {
      background-color: #37AA2A;
    }
    .status.draft {
      background-color: #FF9201;
    }
    .status.expired {
      background-color: #8C8C8C;
    }
    .status-date {
      color: $white;
      height: 24px;
      line-height: 24px;
      display: inline-block;
      vertical-align: middle;
      &::before {
        display: inline-block;
        vertical-align: middle;
        content: '';
        background-image: url('../../images/expire-icon.svg');
        background-repeat: no-repeat;
        background-size: auto 100%;
        margin-top: -4px;
        margin-right: 6px;
        width: 18px;
        height: 18px;
      }
    }
  }