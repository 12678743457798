@import "/src/styles/all-imports";

.divide-line {
  border-top: solid 1px rgba(255,255,255,0.2);
  width: 100%;
  margin: 30px 15px;
  @include resp-tab {
    margin: 50px 15px;
  }
}

.checkbox-wrap {
  position: relative;
  float: left;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  .control-me {
    display: block;
    width: 100%;
    height: 20px;
    &::after {
        content: '';
        font-size: 15px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        display: block;
        background-color: $white;
        border: solid 2px $white;
        border-radius: 4px;
    }
  }
  [id^=toggle]:checked ~ .control-me::after,
  .true ~ .control-me::after {
      content: '';
      background-image: url('../../images/checkbox-icon.svg');
      background-repeat: no-repeat;
      background-size: 14px auto;
      background-position: 1px 3px;
      width: 20px;
      height: 20px;
      border: solid 2px $white;
      border-radius: 4px;
  }
  label {
      padding: 0 0 0 30px;
      color: $white;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      line-height: 22px;
      @include font-rg;
      font-size: 14px;
	  user-select: none;
	  cursor: pointer;
  }
  .visually-hidden {
      position: absolute;
      left: -100vw;
      /* Note, you may want to position the checkbox over top the label and set the opacity to zero instead. It can be better for accessibilty on some touch devices for discoverability. */
  }
}

.custom-select-menu {
  .selectric {
    border: none;
    margin-bottom: 20px;
    border-radius: .25rem;
    .button {
      height: 44px;
      background-color: $brand-pink;
      color: $white;
      &::after {
        border-top-color: $white;
      }
    }
    .label {
      height: 44px;
      font-size: 14px;
      line-height: 44px;
      @include resp-tab {
        font-size: 15px;
      }
    }
  }
  .selectric-items ul, .selectric-items li {
    font-size: 15px;
  }
}

#miles-small {
  width: 100%;
  height: 44px;
  font-size: 15px;
  border: none;
  box-shadow: none;
  .select__menu {
    z-index: 100;
  }
  .select__control {
    height: 44px;
    .select__value-container {
      height: 44px;
      .select__single-value {
        font-size: 15px;
        padding: 0 0.75rem;
      }
    }
  }
}


.auto-complete-list {
	z-index: 10!important;
}