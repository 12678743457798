@import '/src/styles/all-imports';
/* SEARCH RESULTS PAGE ====================================================== */
#sidebar-search-select {
  display: block;
}
.search-res-title {
  padding-top: 30px;
  padding-bottom: 30px;
  h4 {
    margin-bottom: 0;
    @include font-rg;
    @include resp-tab {
      font-size: 18px;
    }
    @include resp-desk {
      font-size: 20px;
    }
    br {
      @include resp-tab {
        display: none;
      }
    }
  }
}
.date-range {
  input {
    width: calc(50% - 6px);
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 15px;
    margin-right: 6px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}
.circle-bg {
  position: relative;
}
.circle-bg::before {
  @include resp-tab {
    background-image: url('../../images/large-circle.svg');
    background-repeat: no-repeat;
    background-position: right -10% bottom;
    background-size: 41% auto;
    background-attachment: fixed;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    opacity: 0.05;
  }
}
.row.max-width {
  max-width: 1700px;
  margin-left: -15px;
  margin-right: -15px;
  @include resp-tab {
    margin-left: auto;
    margin-right: auto;
  }
}
.sidebar {
  height: 0;
  width: 100%;
  background-color: $brand-dark-purple;
  position: fixed;
  z-index: 200;
  padding: 0px;
  left: 0px;
  top: 58px;
  overflow: hidden;
  border-radius: 10px;
  @include resp-desk {
    height: calc(100% - 135px);
    width: calc(25% - 35px);
    background-color: $brand-dark-purple;
    left: 30px;
    top: 105px;
  }
  &.open {
    height: calc(100% - 58px);
    overflow-y: scroll;
    padding: 0 0 59px 0;
    .update-res {
      display: block;
    }
  }
  .refine-opt {
    padding: 0px 25px 20px 25px;
    overflow-y: hidden;
    @include resp-desk {
      padding: 20px 25px 20px 25px;
      overflow-y: auto;
      height: calc(100% - 40px);
    }
    &::after {
      display: none;
    }
    div:last-of-type {
      ul {
        margin-bottom: 8px;
      }
    }
    .form-control {
      width: 100%;
      float: left;
      margin-bottom: 10px !important;
    }
  }
  .title {
    background-color: $brand-pink;
    height: 60px;
    padding: 0 25px;
    text-align: left;
    position: fixed;
    z-index: 1;
    display: none;
    @include resp-desk {
      height: 47px;
      position: relative;
      display: block;
    }
    h4 {
      margin-bottom: 0;
      @include font-rg;
      line-height: 49px;
    }
    h4::before {
      content: '';
      width: 24px;
      height: 47px;
      background-image: url('../../images/refine-icon.svg');
      background-repeat: no-repeat;
      background-size: 90% auto;
      background-position: left 13px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .title::before {
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    left: 25px;
    bottom: -10px;
    background-color: $brand-pink;
    transform: rotate(45deg);
  }
  h5 {
    font-size: 17px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: $brand-pink;
    @include font-bd;
    @include resp-desk {
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 12px;
    }
  }
  ul {
    list-style: none;
    clear: both;
    li {
      margin: 0;
      padding: 0;
      margin-bottom: 10px;
      position: relative;
      @include resp-desk {
        margin-bottom: 0px;
      }
      p {
        color: $white;
        position: absolute;
        z-index: 1;
        right: 0;
        margin: 0;
        @include font-bd;
        line-height: 38px;
        padding-right: 10px;
        @include resp-desk {
          line-height: 18px;
          color: $brand-pink;
        }
      }
    }
  }
  .styled-checkbox + label {
    color: $white;
    margin-bottom: 0;
    width: 100%;
    padding: 7px 10px;
    border-radius: 10px;
    border: solid 1px #5a4f61;
    @include resp-tab {
      padding: 8px 10px 6px 10px;
    }
    @include resp-desk {
      margin-bottom: 15px;
      padding: 0;
      border: none;
      border-radius: 0;
      font-size: 14px;
    }
    &::before {
      @include resp-tab {
        margin-top: -3px;
      }
      @include resp-desk {
        margin-top: 0;
      }
    }
  }
  .update-res {
    width: 100%;
    padding: 10px 25px;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 59px;
    background-color: $brand-dark-purple;
    z-index: 6;
    display: none;
    @include resp-desk {
      display: none;
    }
    .btn {
      width: 100%;
    }
  }
}

.btn.trigger {
  display: block;
  position: fixed;
  bottom: 30px;
  left: 50%;
  margin-left: -80px;
  min-width: 160px;
  background-color: $brand-pink;
  color: $white;
  z-index: 200;
  font-size: 14px;
  &:before {
    content: '';
    width: 20px;
    height: 20px;
    background-image: url('../../images/refine-icon.svg');
    background-repeat: no-repeat;
    background-size: 20px auto;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  @include resp-desk {
    display: none;
  }
}

.search-res-wrap {
  background-color: $brand-purple;
}

.featured-tag {
  background-color: $brand-pink;
  width: auto;
  position: absolute;
  top: 10px;
  left: 130px;
  color: #fff;
  text-align: left;
  font-size: 9px;
  padding: 4px 6px 2px 6px;
  margin-left: 15px;
  text-transform: uppercase;
  font-weight: 700;
  z-index: 1;
  border-radius: 5px;
  @include resp-tab {
    font-size: 11px;
    width: 170px;
    padding: 9px 10px 7px 25px;
    line-height: 1.4em;
    margin-left: 0;
    transform: rotate(-31deg);
    top: -8px;
    left: -25px;
    border-radius: 0;
  }
}

.checkbox-wrap {
  position: relative;
  float: left;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  .control-me {
    display: block;
    width: 100%;
    height: 20px;
    &::after {
      content: '';
      font-size: 15px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      display: block;
      background-color: $white;
      border: solid 2px $white;
      border-radius: 4px;
    }
  }
  [id^='toggle']:checked ~ .control-me::after,
  .true ~ .control-me::after {
    content: '';
    background-image: url('../../images/checkbox-icon.svg');
    background-repeat: no-repeat;
    background-size: 14px auto;
    background-position: 1px 3px;
    width: 20px;
    height: 20px;
    border: solid 2px $white;
    border-radius: 4px;
  }
  label {
    padding: 0 0 0 30px;
    color: $white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    line-height: 22px;
    @include font-rg;
    font-size: 14px;
    user-select: none;
    cursor: pointer;
  }
  .visually-hidden {
    position: absolute;
    left: -100vw;
    /* Note, you may want to position the checkbox over top the label and set the opacity to zero instead. It can be better for accessibilty on some touch devices for discoverability. */
  }
}
#sidebar-search-select {
  width: calc(50% - 5px);
  margin-right: 5px;
  height: 44px;
  font-size: 15px;
  border: none;
  box-shadow: none;
  float: left;
  display: inline-block;
  .select__menu {
    z-index: 100;
  }
  .select__control {
    height: 44px;
    .select__value-container {
      height: 44px;
      .select__single-value {
        font-size: 15px;
        padding: 0 0.75rem;
      }
    }
  }
}

.search-res-wrap {

  .tagCouples,
  .tagAdults,
  .tagTeenagers,
  .tagFamilies,
  .tagChildren312,
  .tagBabiesToddlers,
  .tagPetFriendly,
  .tagIndoor,
  .tagOutdoor,
  .tagSeasonal {
    display: inline-block!important;
  }

  .postTypeHide .postTypeHide {
	  display: none!important;
  }
  .postTypeShow {
	  display: inline-block!important;
  }
  .hideEvent,
  .hideAttraction {
	  display: none!important;
  }

//   .tagHide {
//     display: none;
//   }
//   .tagShow {
// 	  display: initial!important;
//   }
}
.search-wrap .auto-complete-list {
  background-color: #eee;
  list-style: none;
  margin-top: -1px;
  border: solid 1px #ced4da;
  border-radius: 0.25rem;
  position: absolute;
  z-index: 1;
  width: calc(90% - 50px);
  margin-left: 5%;
  margin-right: 5%;
  left: 25px;
  right: 0;
  li {
    text-align: left;
  }
}
.sidebar-search.fw {
  margin-top: 10px;
  margin-bottom: 25px;
  .btn {
    width: calc(50% - 5px);
    margin-left: 5px;
    display: inline-block;
    min-width: 10px;
  }
}
.daysSlider {
  margin-bottom: 20px;
  .rangeslider-horizontal {
    height: 8px;
    .rangeslider__fill {
      background-color: $brand-pink;
    }
    .rangeslider__handle {
      border-radius: 3px;
      width: 20px;
      &::after {
        content: none;
        border-radius: 0;
      }
    }
  }
  .numDays {
    color: $white;
  }
}
