@import '../../styles/all-imports';
.opening-times {
  margin-top: 30px;
  padding-bottom: 10px;
  ul {
    .ot-left, .ot-right {
      width: 50%;
      float: left;
      display: block;
      border-top: solid 1px rgba(255,255,255,0.4);
      height: 44px;
      line-height: 44px;
      margin-bottom: 0;
      color: $white;
    }
  }
}
