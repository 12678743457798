@import '/src/styles/all-imports';
@import './signup-login-modal.scss';
/* NAV ====================================================================== */
.nav {
  width: 100%;
  height: 58px;
  background-color: $brand-dark-purple;
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  @include resp-tab {
    height: 74px;
  }
  .search {
    margin-top: 14px;
    width: 30px;
    height: 30px;
    margin-left: 15px;
    background-image: url('../../images/search.svg');
    background-repeat: no-repeat;
    background-size: 30px 30px;
    &:hover {
      cursor: pointer;
    }
    @include resp-tab {
      margin-top: 18px;
      width: 36px;
      height: 36px;
      background-size: 36px 36px;
      margin-left: 25px;
    }
  }
  .logo {
    width: 90px;
    height: 38px;
    position: absolute;
    z-index: 100;
    left: 50%;
    margin-left: -45px;
    margin-top: 10px;
    @include resp-tab {
      width: 120px;
      height: 38px;
      margin-left: -60px;
      margin-top: 18px;
    }
  }
  .btn {
    position: absolute;
    right: 95px;
    top: 13px;
    display: none;
    @include resp-tab {
      display: block;
      top: 17px;
    }
    @include resp-desk {
      top: 13px;
    }
  }
  .logged-in {
    position: absolute;
    right: 50px;
    top: 8px;
    height: 48px;
    padding: 5px 5px 5px 5px;
    border-radius: 24px;
    display: inline-block;
    vertical-align: middle;
    @include resp-tab {
      right: 90px;
      top: 13px;
    }
    @include resp-desk {
      right: 90px;
      top: 13px;
      padding: 5px 20px 5px 5px;
    }
    &:hover {
      background-color: $white;
      text-decoration: none;
      p {
        color: $brand-purple;
      }
    }
    .profile-img {
      width: 32px;
      height: 32px;
      border-radius: 19px;
      background-color: $white;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
      margin-right: 6px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      @include resp-tab {
        width: 38px;
        height: 38px;
      }
    }
    p {
      line-height: 36px;
      margin: 0;
      display: inline-block;
      vertical-align: middle;
      display: none;
      @include resp-tab {
        display: inline-block;
      }
    }
  }
  .hamburger {
    position: absolute;
    right: 0px;
    top: 3px;
    outline: none;
    z-index: 1000;
    @include resp-tab {
      right: 10px;
      top: 11px;
    }
  }
  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    background-color: $white;
    height: 2px;
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner:after,
  .hamburger.is-active .hamburger-inner:before {
    background-color: $white;
  }
  .hamburger-box {
    width: 32px;
    @include resp-tab {
      width: 40px;
    }
    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      width: 32px;
      @include resp-tab {
        width: 40px;
      }
    }
  }
}

.nav-panel {
  width: 90%;
  height: 100%;
  position: fixed;
  z-index: 999;
  right: -90%;
  top: 0;
  bottom: 0;
  background-color: rgba(29,9,48,0.8);
  opacity: 0;
  @include resp-tab {
    width: 400px;
    right: -400px;
  }
  ul {
    margin: 0 0 0 60px;
    list-style: none;
    li {
      line-height: 1.4em;
      font-size: 34px;
      margin: 10px 0;
      a {
        color: $white;
        &:hover {
          color: $brand-pink;
          text-decoration: none;
        }
      }
    }
  }
  .nav-panel-flex {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    .btn {
      display: block;
      position: initial;
      @include resp-tab {
        display: none;
      }
    }
    .nav-signout, .nav-signin {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;
      .btn {
        display: block;
        position: static;
        background: transparent;
        line-height: 1.4em;
        font-size: 24px;
        padding: 0;
        margin: 0;
        font-weight: normal;
        width: 100%;
        background-color: $brand-pink;
        border-radius: none;
        text-align: left;
        padding: 20px 60px 15px 60px;
        border-radius: 0;
        border: none;
        &::after {
          content: none;
        }
        &:hover {
          background-color: $white;
          color: $brand-pink;
          transform: none;
          &::before {
            background-image: url('../../images/logout-icon.svg');
          }
        }
        &::before {
          content: '';
          width: 25px;
          height: 25px;
          display: inline-block;
          margin-right: 10px;
          background-image: url('../../images/logout-icon-white.svg');
          vertical-align: middle;
          margin-top: -4px;
        }
      }
    }
    .nav-signin .btn {
      &:hover {
        &::before {
          background-image: url('../../images/login-icon.svg');
        }
      }
      &:before {
        background-image: url('../../images/login-icon-white.svg');
      }
    }
  }
  transition: 1s;
  opacity: 1;
  &.is-active {
    transition: 1s;
    right: 0;
  }
}
.nav-panel-flex.is-active {
  li {
    opacity: 0;
    animation: fadeIn 2s ease-out both;
  }
  li:nth-child(1) {
    animation-delay: 0.3s;
  }
  li:nth-child(2) {
    animation-delay: 0.4s;
  }
  li:nth-child(3) {
    animation-delay: 0.5s;
  }
  li:nth-child(4) {
    animation-delay: 0.6s;
  }
  li:nth-child(5) {
    animation-delay: 0.7s;
  }
}
.nav-panel-flex {
  li {
    animation: fadeOut 1s ease-in both;
  }
  li:nth-child(1) {
    animation-delay: 0.9s;
  }
  li:nth-child(2) {
    animation-delay: 0.8s;
  }
  li:nth-child(3) {
    animation-delay: 0.7s;
  }
  li:nth-child(4) {
    animation-delay: 0.6s;
  }
  li:nth-child(5) {
    animation-delay: 0.5s;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }
}

.inner-search {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  top: 58px; /* Nav Height */
  left: 0;
  z-index: 201;
  background-color: rgba(204, 204, 204, 0.5);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  width: 100%;
  height: 90px;
  .search-wrap {
    padding-top: 20px;
    @include resp-tab {
      padding-top: 30px;
    }
  }
  @include resp-tab {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 150px;
    top: 74px; /* Nav Height */
  }

  &.active {
    height: 90px;
    transition: height 0.1s ease;
    @include resp-tab {
      height: 150px;
    }
  }

  &.inactive {
    height: 0px;
    transition: height 0.1s ease;
	overflow: hidden;
  }
}
