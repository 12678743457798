@import '../../styles/all-imports';
.white-bg {
  .listing-contact {
    h3 {
      color: $white;
    }
  }
}
.listing-contact {
  margin-top: 10px;
  background-color: $brand-pink;
  @include resp-tab {
    margin-top: 0;
  }
  h3 {
    color: $white;
  }
  ul {
    list-style: none;
    margin-bottom: 5px;
    li {
      margin: 0;
      color: $white;
      margin-bottom: 6px;
      @include resp-tab {
        margin-bottom: 15px;
      }
      a {
        @include btn;
        color: $brand-pink;
        text-decoration: none;
        background-repeat: no-repeat;
        background-position: 15px center;
        background-size: 20px 20px;
        background-color: #fff;
        padding-left: 46px !important;
        padding-right: 30px !important;
        width: 100%;
        text-align: left;
        font-size: 14px;
        transition: all 0.2s ease-in-out;
        line-height: 21px;
        @include resp-tab {
          line-height: auto;
          background-size: 26px 26px;
          padding-left: 50px !important;
          font-size: 15px;
        }
        &::after {
          content: '';
          float: right;
          width: 30px;
          height: 20px;
          display: inline-block;
          background-image: url('../../images/link-arrow.svg');
          background-repeat: no-repeat;
          background-size: auto 100%;
          background-position: right center;
          position: initial;
          margin-top: 0;
          @include resp-tab {
            content: none;
          }
          @include resp-desk {
            content: '';
            float: right;
            width: 30px;
            height: 20px;
            display: inline-block;
            background-image: url('../../images/link-arrow.svg');
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position: right center;
            position: initial;
            margin-top: 0;
          }
        }
        &:hover {
          text-decoration: none;
          background-color: $brand-pink;
          color: #fff;
          transform: none;
          background-repeat: no-repeat;
          background-position: 15px center;
          background-size: 26px 26px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          padding-right: 15px !important;
        }
      }
    }
  }
  .website-icon {
    background-image: url('../../images/website-icon-pink.svg');
    &:hover {
      background-image: url('../../images/website-icon.svg');
    }
  }
  .ticket-icon {
    background-image: url('../../images/ticket-icon-pink.svg');
    &:hover {
      background-image: url('../../images/ticket-icon.svg');
    }
  }
  .tel-icon {
    background-image: url('../../images/tel-icon-pink.svg');
    &:hover {
      background-image: url('../../images/tel-icon.svg');
    }
  }
}

.listing-cats {
  margin-top: 30px;
  background-color: $brand-purple;
  width: 100%;
  float: left;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    .cat-tick {
      background-image: url('../../images/checkbox-icon.svg');
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 16px auto;
      padding-left: 26px;
      @include resp-tab {
        background-size: 20px auto;
        padding-left: 30px;
      }
    }
    li:last-of-type {
      margin-bottom: 0;
    }
  }
}
