// libs/_color.scss

// Link color
$link-color: #FF1F7C;

// Color
$white: #f5f5f5;
$black: #000;
$grad-black: rgba(0,0,0,0.6);
$grad-black2: rgba(0,0,0,0);
$brand-pink: #ff5a5f;
$brand-dark-pink: #d40f63;
$brand-blue: #008795;
$brand-purple: #1c0931;
$brand-dark-purple: #140621;
$brand-dark-purple-trans: rgba(30,08,46,0);
$brand-dark-purple2: #160125;
$grey: #eeeeee;

// Gradients
$list2: $brand-pink 0%, $brand-dark-pink 100%;
$list4: $brand-purple 0%, $brand-purple 100%;
$list5: $white 0%, $black 100%;
$list6: $grad-black 0%, $grad-black2 100%;
$list7: $brand-dark-purple 0%, $brand-purple 100%;
$list8: $brand-purple 0%, $brand-dark-purple 100%;
$list9: $brand-dark-purple-trans 0%, $brand-dark-purple 100%;
$list10: $grad-black2 0%, $grad-black 100%;
@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}
@mixin grad2 {
  @include gradient(to bottom right, $list2);
}
@mixin grad4 {
  @include gradient(to bottom right, $list4);
}
@mixin grad5 {
  @include gradient(to bottom right, $list5);
}
@mixin grad6 {
  @include gradient(to top, $list6);
}
@mixin grad7 {
  @include gradient(to bottom right, $list7);
}
@mixin grad8 {
  @include gradient(to top, $list8);
}
@mixin grad9 {
  @include gradient(to top, $list9);
}
@mixin grad10 {
  @include gradient(to top, $list10);
}
.grad2 {
  @include grad2;
}
.grad4 {
  @include grad4;
}
.grad5 {
  @include grad5;
}
.grad6 {
  @include grad6;
}
.grad7 {
  @include grad7;
}
.grad8 {
  @include grad8;
}
.grad9 {
  @include grad8;
}
.pink {
  color: $brand-pink;
}
.purple {
  color: $brand-purple;
}
.blue {
  color: $brand-blue;
}
.white {
  color: $white;
}
.white-bg {
  background-color: #fff;
  // h2, h3, h4, h5, h6 {
  //   color: $brand-pink;
  // }
  // p, ul, li, p a {
  //   color: $brand-purple;
  // }
}
