// libs/_common.scss

body {
  font-size: 14px;
  @include font-rg;
  color: #222222;
  background-color: $brand-purple;
  box-sizing: border-box;
}

.fw {
  width: 100%;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
}
.resp-img {
  width: 100%;
  height: auto;
}
// Rounded Corners
@mixin rounded-corners {
  border-radius: 10px;
  @include resp-tab {
    border-radius: 10px;
  }
  @include resp-desk {
    border-radius: 10px;
  }
}
.rd-corners {
  @include rounded-corners;
}
.block {
  padding: 15px;
  @include rounded-corners;
  @include resp-tab {
    padding: 15px;
  }
  @include resp-desk {
    padding: 25px;
  }
}

// Btns
@mixin btn {
  display: inline-block;
  padding: 7px 12px 6px 12px;
  text-align: center;
  background-color: $brand-pink;
  border: none;
  color: $white;
  text-decoration: none;
  font-size: 12px;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 8px;
  transition: all .2s ease-in-out;
  font-weight: normal;
  letter-spacing: -.01em;
  border: solid 2px $brand-pink;
  position: relative;
  min-width: 100px;
  @include font-bd;
  @include resp-tab {
    min-width: none;
  }
  &::after {
    @include resp-desk {
      content: '';
      width: 10px;
      height: 14px;
      display: inline-block;
      background-image: url('../../images/chev-right-pink-btn.svg');
      background-repeat: no-repeat;
      background-size: auto 14px;
      background-position: center center;
      margin-left: 8px;
      position: absolute;
      top: 50%;
      margin-top: -7px;
      right: 16px;
      opacity: 0;
      transition: all .2s ease-in-out;
    }
  }
  @include resp-tab {
    padding: 9px 12px 8px 12px;
    border-radius: 10px;
    font-size: 12px;
  }
  @include resp-desk {
    padding: 11px 25px 9px 25px;
    font-size: 14px;
  }
  &:hover {
    background: transparent;
    color: $brand-pink;
    text-decoration: none;
    &::after {
      @include resp-desk {
        right: 10px;
        opacity: 1;
      }
    }
    @include resp-desk {
      padding: 11px 35px 9px 15px;
    }
  }
  &:focus {
    box-shadow: none;
  }
}
@mixin btn-blue {
  @include btn;
  background-color: $brand-blue;
  &:hover {
    color: $brand-blue;
  }
}
@mixin btn-purple {
  @include btn;
  background-color: $brand-purple;
  border-color: $brand-purple;
  &::after {
    background-image: url('../../images/chev-right-purple-btn.svg');
  }
  &:hover {
    color: $brand-purple;
  }
}
.btn {
  @include btn;
}
.btn-purple {
  @include btn-purple;
}
.btn-white {
  @include btn;
  background-color: $white;
  color: $brand-pink;
  border: solid 2px $white;
  &::after {
    background-image: url('../../images/chev-right-white-btn.svg');
  }
  &:hover {
    color: $white;
  }
}
.btn-lg {
  @include btn;
  padding: 15px 18px;
  font-size: 16px;
}
.btn-gh-rd {
  @include btn;
  border-radius: 10px;
  background: transparent;
  border: solid 2px $brand-pink;
  &:hover {
    background-color: transparent;
    color: $white;
  }
}
.btn-gh-rd-lg {
  @include btn;
  border-radius: 10px;
  padding: 15px 18px;
  font-size: 16px;
  background: transparent;
  border: solid 2px $brand-pink;
  &:hover {
    background-color: transparent;
    color: $white;
  }
}

.form-control {
  margin-bottom: 10px;
  height: 38px;
  font-size: 14px;
  color: $brand-dark-purple;
  @include resp-tab {
    font-size: 15px;
    margin-bottom: 20px;
    height: 44px;
  }
}
textarea.form-control {
  margin-bottom: 20px;
  height: 200px;
  font-size: 15px;
  color: $brand-dark-purple;
}

// Box Shadow
@mixin box-shadow {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
}
@mixin box-shadow2 {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
}
@mixin box-shadow-hover {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.50);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.50);
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.50);
}
@mixin box-shadow-hover-light {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.20);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.20);
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.20);
}

// Section Padding
.sect-pad-top-sm {
  padding-top: 20px;
  @include resp-tab {
    padding-top: 30px;
  }
  @include resp-desk {
    padding-top: 40px;
  }
}
.sect-pad-bot-sm {
  padding-bottom: 20px;
  @include resp-tab {
    padding-bottom: 30px;
  }
  @include resp-desk {
    padding-bottom: 40px;
  }
}
.sect-pad-top {
  padding-top: 30px;
  @include resp-tab {
    padding-top: 40px;
  }
  @include resp-desk {
    padding-top: 80px;
  }
}
.sect-pad-bot {
  padding-bottom: 30px;
  @include resp-tab {
    padding-bottom: 40px;
  }
  @include resp-desk {
    padding-bottom: 80px;
  }
}
.sect-pad-top-lg {
  padding-top: 30px;
  @include resp-tab {
    padding-top: 50px;
  }
}
.sect-pad-bot-lg {
  padding-bottom: 30px;
  @include resp-tab {
    padding-bottom: 50px;
  }
}

// Placeholder
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccc;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: #ccc;
}

/* Iphone Tel No Links ====================================================== */
a[href^="tel"] {
  color: $white;
}

/* Booststrap Grid Overrides */
@include resp-tab {
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto {
    padding-right: 10px;
    padding-left: 10px;
  }
  .row {
    margin-right: -10px;
    margin-left: -10px;
  }
}
@include resp-tab {
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto {
    padding-right: 15px;
    padding-left: 15px;
  }
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}
