@import "/src/styles/all-imports";

.google-btn-wrap {
  margin-top: 20px;
  @include resp-tab {
    margin-top: 30px;
  }
  .btn {
    background-image: url('../../images/google-logo.svg');
    background-repeat: no-repeat;
    background-size: 20px auto;
    background-position: 10px center;
    padding-left: 40px;
    &:hover {
      background-image: url('../../images/google-logo-pink.svg');
      background-repeat: no-repeat;
      background-size: 20px auto;
      background-position: 10px center;
      padding-left: 40px;
    }
  }
}
