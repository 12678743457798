@import '/src/styles/all-imports';
.basic-multi-select {
  display: inline-block;
  vertical-align: middle;
  height: 50px;
  width: 25%;
  display: none;
  cursor: pointer;
  @include resp-tab {
    height: 75px;
    width: 18%;
    display: inline-block;
  }
  @include resp-desk {
    height: 86px;
    width: 20%;
  }
  .select__control {
    height: 49px;
    border: 0;
    border-radius: 0;
    width: 100%;
    background-color: #f5f5f5;
    @include resp-tab {
      height: 75px;
    }
    @include resp-desk {
      height: 85px;
    }
    .select__value-container {
      height: 49px;
      padding: 0;
      border-left: solid 1px #ccc;
      @include resp-tab {
        height: 75px;
      }
      @include resp-desk {
        height: 85px;
      }
    }
    .select__single-value {
      height: 49px;
      line-height: 49px;
      width: 100%;
      max-width: 100%;
      font-size: 16px;
      margin: 0;
      padding: 0;
      @include resp-tab {
        height: 75px;
        line-height: 75px;
        font-size: 18px;
      }
      @include resp-desk {
        height: 85px;
        line-height: 85px;
        font-size: 20px;
      }
    }
    .select__indicator-separator {
      display: none;
    }
  }
  .select__menu {
    background-color: $white;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    height: auto;
    margin: -1px 0 0 0;
  }
  .select__control--is-focused {
    border: none;
    box-shadow: none;
  }
  .select__menu-list {
  	padding: 0;
    margin-bottom: -4px;
  	margin-top: 0;
  }
}
