@mixin resp-tab {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin resp-desk {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin resp-xl {
  @media (min-width: 1200px) {
    @content;
  }
}
