@import "/src/styles/all-imports";
.registerForm {
  > p {
    margin-top: 20px;
    background-color: $brand-purple;
    padding: 15px;
    border-radius: 8px;
    color: $white !important;
    width: 100%;
  }
}
.userLogin, .userRegister {
  max-width: 700px;
  margin: 0 auto;
}
.userLogin {
  hr {
    margin-top: 35px;
    margin-bottom: 30px;
  }
}
