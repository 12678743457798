@import "/src/styles/all-imports";

.eblock-wrap .favbtn {
	cursor: pointer;
	position: absolute;
	z-index: 4;
	top: 8px;
	left: 15px;
	@include resp-tab {
		top: 13px;
		left: 20px;
	}
}
.heart- {
  &stroke{
    fill: none;
    stroke: #fff;
    stroke-width: 2px;
    opacity: 1;
    transform-origin: center center;
    .favbtn.active & {
      opacity: 0;
    }
  }

  &full{
    opacity: 0;
    transform-origin: 50% 50%;

    .favbtn.active & {
      opacity: 1;
			fill: #fff;
    }
  }

  &lines {
    stroke-width: 2px;
    display: none;
  }
}
.favbtn:not(.active):hover {
  .heart-stroke {
    animation: pulse 1s ease-out infinite;
  }
}

.favbtn.animate {
  .heart-full {
    animation: heart 0.35s;
  }
  .heart-lines {
    animation: lines 0.2s ease-out forwards;
    display: block;
  }
}

@keyframes lines {
  0%   {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes heart {
	0% {
		transform: scale(1);
		transform-origin: center center;
		animation-timing-function: ease-out;
	}
	10% {
		transform: scale(1.2);
		animation-timing-function: ease-in;
	}
	35% {
		transform: scale(1);
		animation-timing-function: ease-out;
	}
	75% {
		transform: scale(1.1);
		animation-timing-function: ease-in;
	}
  100% {
    transform: scale(1);
		animation-timing-function: ease-out;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.interested svg {
	overflow: visible;
}
.cara-sidebar .interested svg .heart-stroke {
	stroke-width: 1px;
	stroke: $brand-pink;
}
.eblock-wrap.event {
	svg .heart-stroke {
		stroke-width: 1px;
	}
	svg {
		overflow: visible;
		margin-left: 7px;
	}
}
.attraction .cara-sidebar .interested svg .heart-stroke {
	stroke-width: 1px;
	stroke: $brand-blue;
}
