#logModal {
  margin-left: 15px;
  margin-right: 15px;
  @include resp-tab {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  .modal-content {
    border-radius: 10px;
    .modal-body {
      padding: 20px 5px;
      @include resp-tab {
        padding: 30px 1rem;
      }
      hr {
        width: calc(100% - 30px);
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 25px;
        margin-bottom: 20px;
        border-top: solid 1px #ddd;
        @include resp-tab {
          margin-top: 35px;
          margin-bottom: 30px;
        }
      }
      h2 {
        color: $brand-purple;
      }
      p a:link,
      a:visited {
        color: $brand-pink;
      }
      label {
        color: $brand-purple;
        margin-bottom: 6px;
      }
    }
  }
}
