@import "/src/styles/all-imports";
.unverifiedAccount {
  background-color: #fff;
  h2 {
    color: $brand-pink;
    &::before {
      content: '';
      width: 80px;
      height: 80px;
      display: block;
      margin: 0 auto 15px auto;
      background-image: url('../../images/email-veri-icon.svg');
      @include resp-tab {
        width: 100px;
        height: 100px;
        margin: 0 auto 25px auto;
      }
    }
  }
  p {
    color: $brand-purple;
    margin-bottom: 15px;
    @include resp-tab {
      margin-bottom: 25px;
    }
  }
}
