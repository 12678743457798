@import '/src/styles/all-imports';
#home-carousel {
  .carousel-indicators {
    margin-bottom: 30px;
    z-index: 101;
    li {
      width: 15px;
      height: 15px;
      border-radius: 10px;
      margin: 0 10px;
      display: block;
      border: solid 2px $white;
      box-sizing: border-box;
      background: transparent;
      opacity: 1;
      &.active {
        background-color: $white;
      }
    }
  }
}
/* CAROUSEL ================================================================= */
.carousel {
  position: relative;
  float: left;
  width: 100%;
  background-color: $brand-pink;
}
.carousel-item {
  height: 260px;
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  @include resp-tab {
    background-position: center center;
    height: 480px;
  }
  @include resp-desk {
    height: 680px;
  }
  @include resp-xl {
    background-size: auto;
    background-position: right center;
    height: 680px;
  }
}
.cara-overlay {
  width: 100%;
  height: 260px;
  position: absolute;
  z-index: 100;
  overflow: hidden;
  @include resp-tab {
    height: 480px;
  }
  @include resp-desk {
    height: 680px;
  }
  @include resp-xl {
    height: 680px;
  }
  h1 {
    width: 100%;
    @include font-rg;
    margin-bottom: 10px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    @include resp-tab {
      font-size: 40px;
      margin-bottom: 20px;
      margin-top: -23px;
      text-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    }
    @include resp-desk {
      font-size: 50px;
      margin-bottom: 25px;
    }
    @include resp-xl {
      font-size: 60px;
      margin-bottom: 25px;
    }
  }
  .pattern1,
  .pattern2 {
    position: absolute;
    background-image: url('../../images/pattern1.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .pattern1 {
    width: 280px;
    height: 280px;
    left: 10%;
    top: 50%;
    margin-top: -140px;
    display: none;
    @include resp-tab {
      display: block;
      width: 180px;
      height: 180px;
      left: 3%;
      top: 61%;
      margin-top: -140px;
    }
    @include resp-desk {
      width: 280px;
      height: 280px;
      left: 10%;
      top: 50%;
      margin-top: -140px;
    }
  }
  .pattern2 {
    background-image: url('../../images/pattern2.svg');
    width: 100px;
    height: 100px;
    right: 0;
    bottom: -24px;
    margin-top: -140px;
    @include resp-tab {
      width: 190px;
      height: 190px;
      bottom: 0;
    }
    @include resp-desk {
      width: 280px;
      height: 280px;
      bottom: 0;
    }
  }
}
.search-wrap {
  width: 100%;
  @include resp-desk {
    padding-left: 5%;
    padding-right: 5%;
  }
  .main-search-input {
    height: 50px;
    width: 80%;
    border-radius: 12px 0 0 12px;
    outline: none;
    border: none;
    padding: 20px;
    font-size: 16px;
    color: #222;
    display: inline-block;
    vertical-align: middle;
    @include resp-tab {
      height: 75px;
      width: 60%;
      font-size: 18px;
    }
    @include resp-desk {
      height: 85px;
      padding: 20px 30px;
      font-size: 20px;
    }
  }
  .selectric-wrapper {
    height: 85px;
    width: 20%;
    display: none;
    vertical-align: middle;
    margin-left: -4px;
    @include resp-tab {
      display: inline-block;
    }
  }
  .selectric {
    border: none;
    border-left: solid 1px #ccc;
    background-color: $white;
  }
  .selectric,
  .selectric .label,
  .selectric .button {
    height: 85px;
  }
  .selectric .label {
    line-height: 85px;
    font-size: 20px;
    color: $brand-dark-purple;
  }
  .selectric-items li {
    font-size: 18px;
    line-height: 30px;
    min-height: 30px;
  }
  .selectric .button {
    background-color: $white;
  }
  .btn {
    height: 50px;
    line-height: 50px;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    width: 18%;
    display: inline-block;
    vertical-align: middle;
    border: none;
    outline: none;
    margin-left: -4px;
    border-radius: 0 12px 12px 0;
    background-color: $brand-pink;
    color: $white;
    min-width: 10px;
    background-image: url('../../images/search.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center center;
    overflow: hidden;
    text-indent: 200px;
    &::after {
      content: none;
    }
    @include resp-tab {
      width: 20%;
      height: 75px;
      line-height: 78px;
      border-radius: 0 22px 22px 0;
      text-indent: inherit;
      background-image: none;
      font-size: 18px;
    }
    @include resp-desk {
      font-size: 20px;
      width: 18%;
      height: 85px;
      line-height: 88px;
    }
    &:hover {
      background-color: $brand-purple;
      transform: none;
      padding: 0;
    }
    &::before {
      @include resp-tab {
        content: '';
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
        background-image: url('../../images/search.svg');
        background-repeat: no-repeat;
        background-size: 26px 26px;
      }
      @include resp-desk {
        background-size: 30px 30px;
      }
    }
  }
}
