// Imports
@import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '/src/styles/libs/reset';
@import '/src/styles/libs/responsive';
@import '/src/styles/libs/color';
@import '/src/styles/libs/fonts';
@import '/src/styles/libs/checkboxes';
@import '/src/styles/libs/common';
@import '/src/styles/hamburgers.min.css';
@import '/src/styles/selectric.css';

html {
  scroll-behavior: smooth;
}

body {
  padding-top: 58px;
  @include resp-tab {
    padding-top: 74px;
  }
  &.no-scroll {
    overflow-y: hidden;
  }
}

.disabled {
  opacity: 0.5 !important;
  pointer-events: none;
}

.eblock-wrap.attraction {
  h3 {
    color: $brand-blue;
  }
  .distance {
    background-color: $brand-blue;
  }
}
