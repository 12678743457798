// libs/_fonts.scss
@font-face {
    font-family: 'Gotham Rounded Book Italic';
    src: url('../../fonts/GothamRounded-BookItalic.eot');
    src: url('../../fonts/GothamRounded-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/GothamRounded-BookItalic.woff2') format('woff2'),
        url('../../fonts/GothamRounded-BookItalic.woff') format('woff'),
        url('../../fonts/GothamRounded-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Gotham Rounded Book';
    src: url('../../fonts/GothamRounded-Book.eot');
    src: url('../../fonts/GothamRounded-Book.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/GothamRounded-Book.woff2') format('woff2'),
        url('../../fonts/GothamRounded-Book.woff') format('woff'),
        url('../../fonts/GothamRounded-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gotham Rounded Med';
    src: url('../../fonts/GothamRounded-Medium.eot');
    src: url('../../fonts/GothamRounded-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/GothamRounded-Medium.woff2') format('woff2'),
        url('../../fonts/GothamRounded-Medium.woff') format('woff'),
        url('../../fonts/GothamRounded-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
// Mixins
@mixin font-light {
  font-family: 'Gotham Rounded Book';
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.01em;
}
@mixin font-lg-it {
  font-family: 'Gotham Rounded Book Italic';
  font-weight: normal;
  font-style: italic;
  letter-spacing: -0.01em;
}
@mixin font-rg {
  font-family: 'Gotham Rounded Book';
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.01em;
}
@mixin font-rg-it {
  font-family: 'Gotham Rounded Book Italic';
  font-weight: normal;
  font-style: italic;
  letter-spacing: -0.01em;
}
@mixin font-bd {
  font-family: 'Gotham Rounded Book';
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.01em;
}
@mixin font-bd-it {
  font-family: 'Gotham Rounded Book';
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.01em;
}
h1 {
  font-size: 22px;
  line-height: 1.1em;
  margin-bottom: 10px;
  @include font-rg;
  color: $white;
  @include resp-tab {
    font-size: 32px;
    margin-bottom: 15px;
  }
  @include resp-desk {
    font-size: 34px;
  }
  @include resp-xl {
    font-size: 38px;
  }
}
h2 {
  font-size: 18px;
  line-height: 1.2em;
  margin-bottom: 12px;
  @include font-bd;
  color: $white;
  @include resp-tab {
    font-size: 23px;
    margin-bottom: 12px;
  }
  @include resp-desk {
    font-size: 26px;
    margin-bottom: 20px;
  }
}
h3 {
  font-size: 16px;
  line-height: 1.2em;
  margin-bottom: 12px;
  @include font-bd;
  color: $white;
  @include resp-tab {
    font-size: 18px;
    margin-bottom: 15px;
  }
  @include resp-desk {
    font-size: 20px;
    margin-bottom: 18px;
  }
}
h4 {
  font-size: 16px;
  line-height: 1.25em;
  margin-bottom: 18px;
  @include font-bd;
  color: $white;
  @include resp-tab {
    font-size: 20px;
  }
}
h5 {
  font-size: 16px;
  line-height: 1.3em;
  margin-bottom: 18px;
  @include font-rg;
  color: $white;
}
h6 {
  font-size: 14px;
  line-height: 1.45em;
  margin-bottom: 15px;
  @include font-rg;
  color: $white;
  @include resp-tab {
    font-size: 15px;
  }
}
p {
  font-size: 13px;
  line-height: 1.45em;
  margin-bottom: 15px;
  @include font-rg;
  color: $white;
  @include resp-tab {
    font-size: 13px;
  }
  @include resp-desk {
    font-size: 15px;
    line-height: 1.55em;
  }
}
ul, ol {
  padding: 0;
  li {
    font-size: 13px;
    line-height: 1.45em;
    margin-top: 0;
    margin-bottom: 10px;
    @include font-rg;
    color: $white;
    @include resp-tab {
      font-size: 14px;
      margin-bottom: 15px;
    }
    @include resp-desk {
      font-size: 15px;
      line-height: 1.55em;
    }
  }
}
label {
  font-size: 13px;
  line-height: 1.45em;
  margin-bottom: 5px;
  @include font-rg;
  color: $white;
  display: inline-block;
  vertical-align: middle;
  @include resp-tab {
    font-size: 14px;
    margin-bottom: 15px;
  }
  @include resp-desk {
    font-size: 15px;
  }
}
