@import "/src/styles/all-imports";

.page-template-dashboard {
  .eblock .edit-btn {
    display: none;
    position: absolute;
    top: 50%;
    left: 10%;
    width: 20%;
    margin-left: 0;
    margin-top: -24px;
    min-width: 10px;
    overflow: hidden;
    text-align: center;
    height: 48px;
    background-color: $white;
    border: none;
    padding: 0;
    &::after {
      content: none;
    }
    img {
      width: 31px;
      height: auto;
      display: inline-block;
      position: absolute;
      top: 50%;
      margin-top: -12px;
      margin-left: -15px;
      transition: all 0.3s ease-out;
      opacity: 1;
    }
    p {
      color: $white;
      text-align: center;
      margin-bottom: 0;
      position: absolute;
      left: 0;
      top: 0;
      line-height: 52px;
      width: 100%;
      text-align: center;
      display: block;
      opacity: 0;
      transition: all 0.3s ease-out;
    }
    &:hover {
      transform: scale(1);
      background-color: $brand-purple !important;
      img {
        opacity: 0;
      }
      p {
        opacity: 1;
      }
    }
  }
  .eblock .btn.view-btn {
    display: none;
    position: absolute;
    top: 50%;
    left: 40%;
    width: 20%;
    margin-left: 0;
    margin-top: -24px;
    min-width: 10px;
    overflow: hidden;
    text-align: center;
    height: 48px;
    background-color: $white;
    border: none;
    padding: 0;
    &::after {
      content: none;
    }
    img {
      width: 31px;
      height: auto;
      display: inline-block;
      position: absolute;
      top: 50%;
      margin-top: -12px;
      margin-left: -15px;
      transition: all 0.3s ease-out;
      opacity: 1;
    }
    p {
      color: $white;
      text-align: center;
      margin-bottom: 0;
      position: absolute;
      left: 0;
      top: 0;
      line-height: 52px;
      width: 100%;
      text-align: center;
      display: block;
      opacity: 0;
      transition: all 0.3s ease-out;
    }
    &:hover {
      transform: scale(1);
      background-color: $brand-purple;
      img {
        opacity: 0;
      }
      p {
        opacity: 1;
      }
    }
  }
  .eblock .btn.trash-btn {
    display: none;
    position: absolute;
    top: 50%;
    left: 70%;
    width: 20%;
    margin-left: 0;
    margin-top: -24px;
    min-width: 10px;
    overflow: hidden;
    text-align: center;
    height: 48px;
    background-color: $white;
    border: none;
    padding: 0;
    &::after {
      content: none;
    }
    img {
      width: 31px;
      height: auto;
      display: inline-block;
      position: absolute;
      top: 50%;
      margin-top: -12px;
      margin-left: -15px;
      transition: all 0.3s ease-out;
      opacity: 1;
    }
    p {
      color: $white;
      text-align: center;
      margin-bottom: 0;
      position: absolute;
      left: 0;
      top: 0;
      line-height: 52px;
      width: 100%;
      text-align: center;
      display: block;
      opacity: 0;
      transition: all 0.3s ease-out;
    }
    &:hover {
      transform: scale(1);
      background-color: $brand-purple;
      img {
        opacity: 0;
      }
      p {
        opacity: 1;
      }
    }
  }
  .eblock:hover {
    transform: none;
    @include box-shadow;
    .btn {
      display: inline-block;
    }
  }
  .banner-inner.dashboard {
    .btn {
      margin-top: 10px;
      @include resp-tab {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -24px;
      }
    }
  }
  .status-wrap {
    z-index: 1;
    padding: 0 0 10px 0;
    border-radius: 10px 10px 0 0;
    background: none;
    @include resp-tab {
      padding: 15px;
      position: absolute;
      top: 0;
      left: 15px;
      right: 15px;
      @include grad10;
    }
    .status {
      color: $white;
      height: 24px;
      line-height: 24px;
      padding: 0 10px;
      min-width: 70px;
      text-transform: uppercase;
      font-size: 12px;
      @include font-bd;
      border-radius: 4px;
      display: inline-block;
      text-align: center;
      float: right;
    }
    .status.live {
      background-color: #37AA2A;
    }
    .status.draft {
      background-color: #FF9201;
    }
    .status.expired {
      background-color: #8C8C8C;
    }
    .status-date {
      color: $white;
      height: 24px;
      line-height: 24px;
      display: inline-block;
      vertical-align: middle;
      &::before {
        display: inline-block;
        vertical-align: middle;
        content: '';
        background-image: url('../../images/expire-icon.svg');
        background-repeat: no-repeat;
        background-size: auto 100%;
        margin-top: -4px;
        margin-right: 6px;
        width: 18px;
        height: 18px;
      }
    }
  }
}

.add-listing-wrap {
  border-radius: 10px;
  margin-bottom: 30px;
  width: 100%;
  display: block;
  @include resp-tab {
    border: solid 1px rgba(255,255,255,0.1);
    height: 323px;
    margin-bottom: 0px;
  }
  .add-listing {
    background-color: $brand-pink;
    display: block;
    width: 100%;
    height: 88px;
    margin: 15px auto 15px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    @include resp-tab {
      width: 200px;
      height: 200px;
      margin: 61px auto 0 auto;
      border-radius: 22px;
    }
    &:hover {
      text-decoration: none;
      transform: scale(1.04);
    }
    img {
      width: 20px;
      height: auto;
      margin-bottom: 10px;
      @include resp-tab {
        width: 30px;
      }
    }
    h3 {
      color: $white;
      margin: 0;
    }
  }
}
