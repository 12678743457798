@import '../../styles/all-imports';
.social-links {
  margin-top: 30px;
  h3 {
    color: $brand-purple;
  }
  li {
    display: inline-block;
    margin-right: 6px;
    width: auto;
    @include resp-tab {
      margin-right: 16px;
    }
    @include resp-desk {
      margin-right: 24px;
    }
    &:last-of-type {
      margin-right: 0;
    }
    a {
      width: 100%;
      display: block;
      background-color: $brand-pink;
      color: $white;
      padding: 9px 12px 8px 12px;
      @include resp-tab {
        padding: 9px 14px 8px 12px;
      }
      @include resp-desk {
        padding: 13px 25px 11px 25px;
      }
      &::after {
        content: none;
      }
      &::before {
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: center center;
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
        content: '';
        @include resp-tab {
          width: 25px;
          height: 25px;
          background-size: 25px 25px;
          margin-right: 10px;
        }
      }
      &.fb-link {
        &::before {
          background-image: url('../../images/facebook-icon.svg');
        }
        &:hover {
          &::before {
            background-image: url('../../images/facebook-icon-pink.svg');
          }
        }
      }
      &.tw-link {
        &::before {
          background-image: url('../../images/twitter-icon.svg');
        }
        &:hover {
          &::before {
            background-image: url('../../images/twitter-icon-pink.svg');
          }
        }
      }
      &.in-link {
        &::before {
          background-image: url('../../images/instagram-icon.svg');
        }
        &:hover {
          &::before {
            background-image: url('../../images/instagram-icon-pink.svg');
          }
        }
      }
      &:hover {
        background: transparent;
        color: $brand-pink;
        padding: 9px 12px 8px 12px;
        @include resp-tab {
          padding: 11px 12px 10px 12px;
        }
        @include resp-desk {
          padding: 13px 25px 11px 25px;
        }
      }
    }
  }
}
