@import "/src/styles/all-imports";

ul.account-nav {
  display: inline-block;
  margin-bottom: 10px;
  @include resp-tab {
    margin-bottom: 40px;
  }
  li {
    display: inline-block;
    width: 25%;
    height: 40px;
    min-width: 150px;
    text-align: center;
    line-height: 40px;
    border: solid 1px $brand-pink;
    border-radius: 25px;
    box-sizing: border-box;
    color: $white;
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 6px;
    background-color: $brand-purple;
    @include resp-tab {
      border-radius: 25px 0 0 25px;
      width: auto;
      min-width: 180px;
      height: 49px;
      line-height: 49px;
      margin-bottom: 0;
      margin-left: 0px;
      margin-right: 0px;
    }
    &.active {
      background-color: $brand-pink;
      border-color: $brand-pink;
      color: $white;
    }
    &:last-of-type {
      @include resp-tab {
        margin-left: -5px;
        border-radius: 0 25px 25px 0;
      }
    }
    a {
      display: block;
      width: 100%;
      padding: 0 15px;
      color: $white;
      &:hover {
        text-decoration: none;
      }
    }
    &:nth-child(2), &:nth-child(3) {
      @include resp-tab {
        border-radius: 0;
        margin-left: -5px;
      }
    }
  }
}
