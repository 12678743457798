@import '/src/styles/all-imports';
.step-process {
  .step-nav {
    display: inline-block;
    a {
      display: inline-block;
      height: 49px;
      min-width: 160px;
      padding: 0 15px;
      text-align: center;
      line-height: 49px;
      border: solid 1px $white;
      border-radius: 25px;
      box-sizing: border-box;
      color: $white;
      margin-right: 15px;
      margin-bottom: 0;
      background-color: $brand-purple;
      &.active {
        background-color: $brand-pink;
        border-color: $brand-pink;
        color: $white;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .activePostType {
    .step-box {
      background-color: #fff;
      -moz-box-shadow: inset 0 0 20px #000000;
      -webkit-box-shadow: inset 0 0 20px rgb(0, 0, 0, 0.1);
      box-shadow: inset 0 0 15px rgb(0, 0, 0, 0.1);
      &:hover {
        -moz-box-shadow: inset 0 0 20px #000000;
        -webkit-box-shadow: inset 0 0 20px rgb(0, 0, 0, 0.1);
        box-shadow: inset 0 0 15px rgb(0, 0, 0, 0.1);
        -webkit-transform: none;
        transform: none;
      }
      p {
        color: $brand-purple;
      }
    }
  }
  .step-box {
    padding: 20px;
    border: 1px solid #ddd;
    @include rounded-corners;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 25px;
    @include resp-tab {
      padding: 30px;
      margin-bottom: 40px;
    }
    &:hover {
      transform: scale(1.04);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      text-decoration: none;
    }
    h2 {
      margin-top: 10px;
      margin-bottom: 10px;
      @include font-rg;
    }
    p {
      margin-bottom: 0;
      color: $brand-purple;
    }
    &.icon img {
      width: 80px;
      height: auto;
      @include resp-tab {
        width: 100px;
        height: auto;
      }
    }
  }
  label {
    margin-bottom: 6px;
  }
  h3 {
    color: $brand-pink;
    @include font-rg;
  }

  .price-box {
    width: 100%;
    float: left;
    padding: 20px;
    background-color: $white;
    @include rounded-corners;
    display: none;
    @include resp-tab {
      padding: 30px;
      width: 33.3333%;
      margin-top: 75px;
      display: block;
    }
    .price-title {
      width: 100%;
      height: 36px;
      background-image: url('../../images/price-title-line.svg');
      background-repeat: repeat-x;
      background-position: left center;
      background-size: 10px 2px;
      @include resp-tab {
        height: 50px;
      }
      h3 {
        font-size: 20px;
        line-height: 36px;
        display: inline-block;
        background-color: $white;
        padding: 0 20px;
        margin-bottom: 0;
        @include resp-tab {
          font-size: 26px;
          line-height: 50px;
        }
      }
    }
    h3 {
      text-align: center;
      color: $brand-dark-purple;
    }
    h3.promo {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 26px;
      margin-top: -18px;
    }
    h4 {
      text-align: center;
      color: $brand-dark-purple;
      font-size: 60px;
      margin-bottom: 8px;
      text-transform: uppercase;
    }
    .btn {
      width: 100%;
      margin-bottom: 25px;
      &:after {
        content: none;
      }
      &:hover {
        background-color: $brand-pink;
        color: $white;
        padding-right: 15px;
      }
    }
    ul {
      list-style: none;
      margin: 0;
      li {
        background-image: url('../../images/tick-icon.svg');
        background-repeat: no-repeat;
        background-size: 16px auto;
        background-position: left 4px;
        padding-left: 25px;
        margin-bottom: 10px;
        line-height: 1.4em;
        color: $brand-dark-purple;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  .price-box.featured {
    text-align: center;
    background-color: $brand-pink;
    display: block;
    @include box-shadow;
    .price-title {
      h3 {
        color: $white;
        background-color: $brand-pink;
      }
    }
    span {
      display: inline-block;
      background-color: $white;
      padding: 7px 15px 4px 15px;
      border-radius: 4px;
      text-transform: uppercase;
      margin-bottom: 12px;
      @include font-bd;
      color: $brand-pink;
    }
    h4,
    li {
      color: $white;
    }
    li {
      background-image: url('../../images/tick-icon-white.svg');
      text-align: left;
    }
    .btn {
      &:hover {
        background-color: white;
        color: $brand-pink;
      }
    }
  }
  .price-box.attraction {
    span {
      color: $brand-blue;
    }
    .btn.btn-white {
      color: $brand-blue;
      background-color: $white;
      &::after {
        content: none;
      }
    }
    .btn {
      background-color: $brand-blue;
    }
  }
  .price-box.attraction.featured {
    background-color: $brand-blue;
    .price-title h3 {
      background-color: $brand-blue;
    }
  }
  &.attraction {
    .Cropper button {
      background-color: $brand-blue;
      border-color: $brand-blue;
      &:hover {
        background-color: $white;
        color: $brand-blue;
      }
      &::after {
        background-image: url('../../images/chev-right-blue-btn.svg');
      }
    }
    h3 {
      color: $brand-blue;
    }
  }
  h3, label {
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize;
    }
}
h2,
p,
label {
  color: $brand-purple;
}
h2.nothing-here {
  color: $white;
}
}

.activePostType {
  .step-box {
    background: #1c0931;
    p {
      color: #fff;
    }
  }
}

.pricetableRow {
  display: none;
  &.activePostType {
    display: flex;
  }
}

.ck-editor__editable {
  min-height: 500px;
}
.ck-editor__nested-editable {
  min-height: 0;
}

figcaption.ck-editor__editable.ck-editor__nested-editable.ck-placeholder {
  min-height: initial;
}
.Cropper {
  width: 100%;
  float: left;
  div {
    float: left;
    min-height: 45px;
    display: flex;
    align-items: center;
    // margin-top: 30px;
    // margin-bottom: 10px;
  }
  button {
    @include btn;
  }
  button:first-of-type {
    margin-right: 10px;
  }
  img {
    width: 100%;
    height: auto;
    &:not(.ReactCrop__image) {
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
  .ReactCrop div img {
    max-height: none;
  }
}

/* Sticky Sidebar */
.sideBarWrap {
  display: inline-block;
  overflow: initial;
  position: sticky;
  a.postPreview {
    position: sticky;
    top: 200px;
    margin-top: 20px;
    height: auto;
    &.attraction {
      h3 {
        color: $brand-blue;
      }
    }
  }
}

#editListing {
  .deleteImage {
    // display: none;
  }
}
.white-bg .checkbox-wrap label {
  color: $brand-purple;
}

.hidden {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.scroll-spy-wrap {
  position: relative;
}
.step-process-nav {
	pointer-events: none;
  // position: fixed;
  z-index: 100;
  width: 100%;
  top: 74px;
  left: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  float: left;
  display: none;
  &.attraction {
    a.active {
      background-color: $brand-blue;
      border-color: $brand-blue;
    }
  }
}
.cont-wrap {
  padding-top: 30px;
}
.auto-complete-list {
  background-color: #eee;
  list-style: none;
  margin-top: -10px;
  border: solid 1px #ced4da;
  border-radius: 0.25rem;
  position: absolute;
  z-index: 1;
  width: calc(100% - 30px);
  li {
    padding: 10px;
    margin-bottom: 0;
    color: $brand-purple;
    &:hover {
      background-color: #ddd;
    }
  }
}
.vert-center {
  height: calc(100vh - 294px);
  min-height: 588px;
  align-items: center;
  display: flex;
}
.time-wrap {
  margin-bottom: 20px;
  p {
    margin-bottom: 0;
  }
  input {
    width: 100%;
    font-size: 14px;
    color: $brand-dark-purple;
    @include resp-tab {
      font-size: 15px;
    }
  }
}
.listing-details-wrap {
  h5 {
    margin-bottom: 10px;
    color: $brand-purple;
    font-family: 'Gotham Rounded Med';
    font-weight: normal;
    font-style: normal;
  }
}
.draft-btn {
  margin-right: 10px;
}
.publish-btn {
  float: right;
}
.mainListingImage {
  font-size: 18px;
  display: block;
  margin-bottom: 5px;
}
#listing-image-0 {
  padding: 20px;
  width: 100%;
  float: left;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 30px;
}
.listingMapContainer {
  margin-bottom: 10px;
}
h2.step-title {
  margin-bottom: 20px;
  @include resp-tab {
    margin-bottom: 30px;
  }
}
.cont-wrap {
  .btn-purple {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.attraction {
  .checkbox-wrap [id^=toggle]:checked ~ .control-me::after, .checkbox-wrap .true ~ .control-me::after {
    background-image: url('../../images/checkbox-icon-blue.svg');
  }
  .btn {
    background-color: $brand-blue;
    border-color: $brand-blue;
    &::after {
      background-image: url('../../images/chev-right-blue-btn.svg');
    }
    &:hover {
      color: $brand-blue;
    }
  }
  .btn.btn-white {
    background-color: $white;
    border-color: $white;
    &::after {
      background-image: url('../../images/chev-right-white-btn.svg');
    }
    &:hover {
      color: $white;
      background: transparent;
    }
  }
}
.ck-content {
  p:first-of-type {
    font-size: 15px;
    @include resp-tab {
      font-size: 16px;
    }
    @include resp-desk {
      font-size: 17px;
    }
  }
  ul {
    margin-left: 16px;
    margin-bottom: 15px;
    list-style: disc;
    li {
      color: $brand-purple;
      margin-bottom: 10px;
    }
  }
  ol {
    margin-left: 16px;
    margin-bottom: 15px;
    li {
      color: $brand-purple;
      margin-bottom: 10px;
    }
  }
  a:link {
    color: $brand-pink;
  }
  blockquote {
    padding: 25px;
    border-left: solid 3px $brand-pink;
    margin: 15px 0 30px 0;
    background-color: #f1f1f1;
    @include rounded-corners;
    p {
      font-size: 25px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
.attraction {
  .ck-content {
    a:link {
      color: $brand-blue;
    }
    blockquote {
      border-left: solid 3px $brand-blue;
    }
  }
}
.banner-inner-para {
  background-color: $brand-pink;
  padding: 30px 0;
  @include resp-tab {
    padding: 50px 0;
  }
  p {
    color: $white;
    font-size: 16px;
    line-height: 1.3em;
    margin-top: 5px;
    margin-bottom: 0;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    @include resp-tab {
      font-size: 17px;
      max-width: none;
      margin-left: 0;
      margin-right: 0;
      line-height: 1.45em;
    }
    @include resp-desk {
      font-size: 19px;
    }
  }
}
.banner-inner-para.attraction {
  background-color: $brand-blue;
}
.activePrice .btn, .attraction .activePrice .btn {
  &::after {
    content: '' !important;
    width: 16px;
    height: 18px;
    display: inline-block;
    position: absolute;
    background-image: url('../../images/tick-icon-pink.svg');
    background-repeat: no-repeat;
    background-size: 16px auto;
    background-position: left 4px;
    opacity: 1;
    margin-top: -2px;
    right: 16px;
    @include resp-tab {
      margin-top: -9px;
    }
  }
  &:hover {
    &::after {
      right: 16px;
    }
  }
}
.attraction .activePrice .btn {
  &::after {
    background-image: url('../../images/tick-icon-blue.svg');
  }
}
.resetBtn {
  margin-bottom: 30px;
}
.ReactCrop {
  margin-top: 30px;
}
.listing-details {
  margin-top: 25px;
}
.event-sel {
  padding-right: 7px;
  @include resp-tab {
    padding-right: 15px;
  }
}
.attraction-sel {
  padding-left: 7px;
  @include resp-tab {
    padding-left: 15px;
  }
}
