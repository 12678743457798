@import "/src/styles/all-imports";
.step-process .loginForm, .loginForm {
  p {
    margin-top: 20px;
    background-color: $brand-purple;
    padding: 15px;
    border-radius: 8px;
    color: $white;
    width: 100%;
  }
}
