@import "/src/styles/all-imports";
#enquiryOptions {
  width: 100%;
  margin-right: 5px;
  margin-bottom: 20px;
  height: 44px;
  font-size: 15px;
  border: none;
  box-shadow: none;
  float: left;
  display: inline-block;
  .select__menu {
    z-index: 100;
  }
  .select__control {
    height: 44px;
    .select__value-container {
      height: 44px;
      .select__single-value {
        font-size: 15px;
        padding: 0 0.75rem;
      }
    }
  }
}
