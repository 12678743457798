
@import "/src/styles/all-imports";

/* Footer =================================================================== */
.footer {
  background-color: $brand-dark-purple;
  text-align: center;
  @include resp-tab {
    text-align: left;
  }
  .sitemap {
    display: none;
    @include resp-tab {
      display: block;
    }
  }
  ul {
    list-style: none;
    padding: 0 60px;
    @include resp-tab {
      padding: 0;
    }
    li {
      font-size: 13px;
      margin: 0 0 5px 0;
      display: inline-block;
      padding: 0 5px;
      @include resp-tab {
        font-size: 13px;
        display: block;
        padding: 0;
      }
      @include resp-desk {
        font-size: 14px;
      }
      @include resp-xl {
        font-size: 15px;
      }
      a {
        color: $white;
        &:hover {
          color: $brand-pink;
          text-decoration: none;
        }
      }
    }
  }
  .ctas {
    .btn {
      width: 120px;
      @include resp-tab {
        width: auto;
      }
    }
    a:first-of-type {
      margin-right: 10px;
    }
  }
  .footer2 {
    padding-top: 30px;
    @include resp-tab {
      padding-top: 40px;
    }
    @include resp-desk {
      padding-top: 50px;
    }
    p {
      margin-bottom: 0;
      @include resp-tab {
        font-size: 13px;
      }
      @include resp-desk {
        font-size: 14px;
      }
      @include resp-xl {
        font-size: 15px;
      }
    }
    ul {
      padding-left: 0;
      padding-right: 0;
      li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 0;
        line-height: 1.45em;
        @include resp-tab {
          font-size: 13px;
          padding-left: 0;
          padding-right: 20px;
        }
        @include resp-desk {
          padding-right: 60px;
          font-size: 14px;
        }
        @include resp-xl {
          font-size: 15px;
        }
      }
    }
    .text-md-right {
      display: none;
      @include resp-tab {
        display: block;
      }
    }
  }
  .logo {
    margin-bottom: 10px;
    width: 90px;
    @include resp-tab {
      margin-bottom: 0;
      width: 120px;
    }
  }
  .btn {
    margin-top: 15px;
    @include resp-tab {
      margin-top: 0;
    }
  }
}
