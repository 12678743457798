.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 4px;
    margin-top: 0;
    @include resp-desk {
      margin-top: -4px;
    }
  }

  // Box hover
  &:hover + label:before {
    background: #ffffff;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label {
    background-color: $brand-pink;
    border-color: $brand-pink;
    @include resp-desk {
      background-color: transparent;
    }
  }
  &:checked + label:before {
    background: #ffffff;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 13px;
    top: 12px;
    width: 14px;
    height: 12px;
    background-image: url('../../images/checkbox-icon.svg');
    background-repeat: no-repeat;
    background-size: 14px auto;
    @include resp-tab {
      left: 13px;
      top: 12px;
    }
    @include resp-desk {
      left: 3px;
      top: 5px;
    }
  }
}
