@import "/src/styles/all-imports";
/* ROUTE BLOCKS ============================================================= */
.su-post-wrap {
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url('../../images/large-double-circle.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50px;
  overflow: hidden;
  width: 100%;
  float: left;
  margin: 0;
  @include resp-tab {
    padding-top: 100px;
    padding-bottom: 100px;

  }
}
/* ROUTE BLOCKS ============================================================= */
.route-block {
  padding: 20px;
  height: 190px;
  width: calc(100% - 40px);
  @include rounded-corners;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;
  @include resp-tab {
    padding: 0px 30px 0px 30px;
    height: 220px;
    width: calc(100% - 10px);
  }
  @include resp-desk {
    padding: 40px 60px 40px 40px;
    width: calc(100% - 20px);
    height: 270px;
  }
}
.route-block.rb-signup {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  background-color: $brand-purple;
  box-shadow: 0 0 20px rgba(0,0,0,0.5);
  @include resp-tab {
    margin-left: 0px;
    margin-right: 20px;
    margin-bottom: 0;
  }
  @include resp-desk {
    margin-right: 20px;
  }
  h2 {
    @include resp-tab {
      margin-bottom: 12px;
    }
  }
  p {
    font-size: 13px;
    @include resp-tab {
      font-size: 16px;
      margin-bottom: 20px;
    }
    @include resp-desk {
      font-size: 17px;
      margin-bottom: 22px;
    }
  }
}
.route-block.rb-post {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0;
  background-color: $brand-pink;
  @include resp-tab {
    margin-left: 20px;
  }
  @include resp-desk {
    margin-left: 20px;
  }
}
.su-post-wrap .pattern1-behind::before {
    content: '';
    background-image: url('../../images/pattern1.svg');
    width: 104px;
    height: 104px;
    position: absolute;
    top: -50px;
    left: -35px;
    background-size: 100% auto;
    display: block;
    opacity: 0.5;
    @include resp-tab {
      width: 194px;
      height: 194px;
      top: -22px;
      left: -6px;
    }
    @include resp-desk {
      width: 304px;
      height: 304px;
      top: -50px;
      left: -35px;
    }
}
.su-post-wrap .pattern2-behind::before {
    content: '';
    background-image: url('../../images/pattern1.svg');
    width: 94px;
    height: 94px;
    position: absolute;
    bottom: -50px;
    right: -35px;
    background-size: 100% auto;
    display: block;
    opacity: 0.5;
    @include resp-tab {
      width: 154px;
      height: 154px;
    }
}

.dark-purple-bg2 {
  background-color: $brand-dark-purple2;
}
.pattern2-behind {
  .route-block {
    margin-bottom: 0px;
  }
}
.sign-up-icon::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  background-image: url('../../images/signup-icon.svg');
  background-repeat: no-repeat;
  background-size: 50px auto;
  right: 20px;
  bottom: 20px;
  @include resp-tab {
    width: 65px;
    height: 65px;
    background-size: 65px auto;
    right: 30px;
    bottom: 25px;
  }
  @include resp-desk {
    width: 90px;
    height: 90px;
    background-size: 90px auto;
    right: 30px;
    bottom: 25px;
  }
}
.post-img {
  position: absolute;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $white;
  z-index: 100;
  @include rounded-corners;
}
.post-img-1 {
  display: none;
  @include resp-tab {
    width: 93px;
    height: 93px;
    top: -30px;
    right: 140px;
    display: block;
    background-image: url('../../images/post-image2.jpg');
  }
}
.post-img-2 {
  width: 50px;
  height: 50px;
  top: -15px;
  right: 65px;
  background-image: url('../../images/post-image1.jpg');
  @include resp-tab {
    width: 155px;
    height: 155px;
    top: -40px;
    right: -45px;
  }
}
.post-img-3 {
  width: 70px;
  height: 70px;
  top: 54px;
  right: 15px;
  background-image: url('../../images/post-image3.jpg');
  @include resp-tab {
    width: 93px;
    height: 93px;
    top: 145px;
    right: -30px;
  }
}
