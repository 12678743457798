.page-template-dashboard .banner-inner.dashboard .cropControls {
  position: fixed;
  z-index: 9999;
  width: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  padding: 0;
  text-align: center;
  max-width: 960px;
  max-height: 80%;
  .btn {
    position: absolute;
    right: 30px;
    top: initial;
    bottom: 30px;
  }
}
.ReactCrop {
  display: inline-block;
  div {
    text-align: center;
    img {
      max-height: 80vh;
    }
  }
}
