@import "/src/styles/all-imports";
#accordion-faqs, #accordion-faqs2 {
  .card-header {
    display: block;
    padding: 15px;
    text-align: left;
    background-color: $brand-purple;
    color: $white;
    border-bottom: solid 1px rgba(255,255,255,0.2);
    border-radius: 10px 10px 0 0;
  }
  .card {
    border: none;
    background-color: $white;
    margin-bottom: 15px;
    border-radius: 0 0 10px 10px;
    &:last-of-type h5 {
      border-bottom: none;
    }
  }
  .card-body {
    text-align: left;
    p {
      color: $brand-dark-purple2;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
